import dynamic from 'next/dynamic';

const Icons = {
  Logo: dynamic(() => import('./Logo')),
  Chevron: dynamic(() => import('./ui/Chevron')),
  Arrow: dynamic(() => import('./ui/Arrow')),
  Close: dynamic(() => import('./ui/Close')),
  Cursor: dynamic(() => import('./ui/Cursor')),
  Home: dynamic(() => import('./ui/Home')),
  Play: dynamic(() => import('./ui/Play')),
  Info: dynamic(() => import('./ui/Info')),
  Question: dynamic(() => import('./ui/Question')),
};

export default Icons;
