import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

type Size = 'medium' | 'preamble' | 'large' | 'xlarge' | 'huge' | 'label';
type MaxWidth = 'medium' | 'large';
type ElementType = 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'p';
type Color =
  | 'inherit'
  | 'blue'
  | 'white'
  | 'grey-900'
  | 'grey-800'
  | 'grey-700'
  | 'grey-500'
  | 'grey-400'
  | 'grey-200'
  | 'grey-50';

const commonClasses = '';

const sizeClasses: Record<Size, string[]> = {
  medium: ['text-medium md:text-medium2'],
  preamble: ['text-medium md:text-large'],
  label: ['text-medium md:text-medium2 !leading-[1.025]'],
  large: ['text-large'],
  xlarge: ['text-xlarge'],
  huge: ['text-xlarge md:text-huge'],
};

const maxWidthClasses: Record<MaxWidth, string[]> = {
  medium: ['max-w-medium'],
  large: ['max-w-3xl'],
};

const colorClasses: Record<Color, string[]> = {
  inherit: ['text-inherit'],
  blue: ['text-blue'],
  white: ['text-white'],
  'grey-900': ['text-grey-900'],
  'grey-800': ['text-grey-800'],
  'grey-700': ['text-grey-700'],
  'grey-500': ['text-grey-500'],
  'grey-400': ['text-grey-400'],
  'grey-200': ['text-grey-200'],
  'grey-50': ['text-grey-50'],
};

type TextProps = PropsWithChildren<{
  className?: string;
  size?: Size;
  color?: Color;
  elementType?: ElementType;
  maxWidth?: MaxWidth;
  bold?: boolean;
  center?: boolean;
  style?: 'sans' | 'serif';
}>;

const Text: FC<TextProps> = (props) => {
  const {
    className,
    children,
    color = 'grey-900',
    size = 'medium',
    elementType = 'span',
    maxWidth,
    bold,
    center,
    style = 'sans',
  } = props;

  const classes = classNames(
    commonClasses,
    ...sizeClasses[size],
    ...colorClasses[color],
    ...(maxWidth ? maxWidthClasses[maxWidth] : []),
    bold ? 'font-bold' : 'font-normal',
    center ? 'text-center' : '',
    style == 'serif' ? 'font-serif' : '',
    className,
  );

  const Elem = elementType as keyof JSX.IntrinsicElements;

  return <Elem className={classes}>{children}</Elem>;
};

export default Text;
