import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

type ContentProps = PropsWithChildren<{
  noMargin?: boolean;
  className?: string;
  wide?: boolean;
}>;

const Content: FC<ContentProps> = ({ children, noMargin, wide, className }) => {
  const classes = classNames(
    `mx-auto px-4 md:px-8 ${noMargin ? '' : 'mb-20'}`,
    wide ? 'max-w-[1400px]' : 'max-w-5xl',
    className,
  );

  return <div className={classes}>{children}</div>;
};

export default Content;
