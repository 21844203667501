import { useGoogleTagManager } from '@grace-studio/graceful-next/hooks';
import Modal from 'components/Modal';
import Button from 'components/ui/Button';
import ContactForm from 'compositions/ContactForm';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUiState, setUi } from 'store/uiSlice';

type ContactFormModalTriggerType = {
  trigger: { type: 'link' | 'button'; text: string; className?: string };
};

export const ContactFormModalTrigger: FC<ContactFormModalTriggerType> = ({
  trigger,
}) => {
  const dispatch = useAppDispatch();
  const ui = useAppSelector(selectUiState) || {};
  const gtm = useGoogleTagManager();

  const handleClick = () => {
    gtm.trackEvent({
      event: 'btn_contact_form',
    });
    dispatch(
      setUi({
        ...ui,
        showContactForm: true,
      }),
    );
  };

  return (
    <>
      {trigger.type === 'button' ? (
        <Button onClick={handleClick} className={trigger.className}>
          {trigger.text}
        </Button>
      ) : (
        <a onClick={handleClick} className={trigger.className}>
          {trigger.text}
        </a>
      )}
    </>
  );
};

const ContactFormModal: FC = () => {
  const dispatch = useAppDispatch();
  const ui = useAppSelector(selectUiState) || {};

  return (
    <Modal
      isOpen={ui?.showContactForm}
      onClose={() =>
        dispatch(
          setUi({
            ...ui,
            showContactForm: false,
          }),
        )
      }
    >
      <ContactForm />
    </Modal>
  );
};

export default ContactFormModal;
