export const NAME_REGEX = /^[\p{L}\p{M}\s'-]+$/u; //{L} = accepts all common leters from all languages, including accents.
//export const NAME_REGEX = /^[a-zA-Zà-öÀ-Öø-ÿĀ-žĀ-Ŀŀ-ňŊŌ-ſ\s'´~¨`-]+$/u;//Switch to this to only accept latin letters
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const ADDRESS_REGEX =
  /^([^,]+),\s*(\d{4}|\d{5}|\d{3}\s\d{2})\s+([^,]+),\s*([^,]+)$/;
// export const ADDRESS_REGEX =
//   /^([^,]+),\s*(\d{5}|\d{3}\s\d{2})\s+([^,]+),\s*([^,]+)$/;

// export const EMAIL_REGEX = /^[\da-z._-]{2,}@[\da-z._-]{2,}(?:\.[a-z]{2,})+$/i;
export const EMAIL_REGEX = /^[\da-z._+-]{2,}@[\da-z._-]{2,}(?:\.[a-z]{2,})+$/i;
export const ZIP_CODE_REGEX = /^\d{3}\s?\d{2}$/;
