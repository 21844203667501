import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { ReactPortal } from '@grace-studio/graceful-next/components';
import Icons from 'components/icons';

type ModalProps = PropsWithChildren<{
  isOpen?: boolean;
  onSave?: () => void;
  onClose?: () => void;
  contentClassName?: string;
  className?: string;
}>;

const Modal: FC<ModalProps> = ({
  isOpen = false,
  contentClassName,
  className,
  children,
  onClose,
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const classes = classNames(
    'rounded-xl bg-white shadow-lg max-h-full',
    'overflow-hidden',
    'transition-all duration-300 w-full max-w-[600px] ease-bounce',
    isLoaded
      ? 'opacity-100 pointer-events-auto translate-y-0 delay-[200ms]'
      : 'opacity-0 pointer-events-none translate-y-10 delay-0 scale-[.9]',
    className,
  );

  const contentClasses = classNames(
    'flex-auto overflow-auto p-8 pt-0 relative w-full',
    contentClassName,
  );

  const backdropClasses =
    'absolute top-0 left-0 right-0 bottom-0 bg-black opacity-30';
  const handleOnClose = useCallback(() => onClose && onClose(), [onClose]);

  useEffect(() => {
    if (isOpen) setTimeout(() => setIsLoaded(true), 150);
    // if (isOpen) setIsLoaded(true);
    else setIsLoaded(false);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ReactPortal portalQuerySelector="body">
      <div
        className={classNames(
          'fixed top-0 z-[200] w-screen h-screen flex items-center justify-center transition-all ',
          isLoaded ? 'opacity-100' : 'opacity-0',
        )}
      >
        <div
          className={backdropClasses}
          onClick={() => handleOnClose()}
          role="button"
          aria-label="Close"
          tabIndex={0}
        />

        <div className={classes}>
          <div className="flex items-center justify-end">
            <div className="p-4 cursor-pointer" onClick={handleOnClose}>
              <Icons.Close />
            </div>
          </div>
          <div className={contentClasses}>{children}</div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default Modal;
