import classNames from 'classnames';
import NextLink from 'next/link';
import React, { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import Text from '../Text';
import { useAppSelector } from 'store/hooks';
import { selectUiState } from 'store/uiSlice';

type Style = 'outline' | 'transparentOutline' | 'solid' | 'onlyText';
type Size = 'small' | 'medium' | 'large';
type Rounded = 'full' | 'xl';
type Color = 'white' | 'blue';

const LoadingSpinner = () => (
  <svg
    className="animate-spin -ml-1 mr-3 h-5 w-5"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const commonClasses =
  'inline-flex items-center justify-center \
  whitespace-nowrap w-auto \
  border-2 transition small-caps cursor-pointer';

const focusClasses =
  'focus:outline-none focus-visible:outline-offset-4 \
  focus-visible:outline-4 focus-visible:outline-focus';

const hoverClasses =
  'betterhover:hover:bg-grey-800 betterhover:hover:border-grey-800 betterhover:hover:text-white hover:no-underline';

const activeClasses = 'active:translate-y-1';

const sizeClasses: Record<Size, string[]> = {
  small: ['h-8 px-4'],
  medium: ['h-12 px-4 pb-[3px]'],
  large: ['h-16 px-4'],
};

const textElemProps: Record<Size, React.ComponentProps<typeof Text>> = {
  small: { size: 'medium', color: 'inherit', bold: true },
  medium: { size: 'medium', color: 'inherit', bold: true },
  large: { size: 'medium', color: 'inherit', bold: true },
};

const roundedClasses: Record<Rounded, string[]> = {
  full: ['rounded-full'],
  xl: ['rounded-xl'],
};

const colorSolidClasses: Record<Color, string[]> = {
  white: ['bg-white text-blue border-transparent'],
  blue: ['bg-blue text-white border-transparent'],
};

const colorOutlineClasses: Record<Color, string[]> = {
  white: ['bg-blue border-white text-white'],
  blue: ['bg-white border-blue text-blue'],
};

const transparentColorOutlineClasses: Record<Color, string[]> = {
  white: ['border-white text-white'],
  blue: ['border-blue text-blue'],
};

const colorOnlyTextClasses: Record<Color, string[]> = {
  white: [
    'text-white h-[1em] px-0 !bg-transparent betterhover:hover:text-grey-800',
  ],
  blue: [
    'text-blue h-[1em] px-0 !bg-transparent !border-transparent betterhover:hover:text-grey-800',
  ],
};

const styleClasses: Record<Style, Record<Color, string[]>> = {
  outline: colorOutlineClasses,
  transparentOutline: transparentColorOutlineClasses,
  solid: colorSolidClasses,
  onlyText: colorOnlyTextClasses,
};

const disabledClasses: Record<Style, string[]> = {
  outline: [
    'cursor-not-allowed !border-disabled !bg-transparent !text-disabled hover:!ring-0 \
    hover:!ring-offset-0 active:!translate-y-0',
  ],
  transparentOutline: [
    'cursor-not-allowed !border-disabled !bg-transparent !text-disabled hover:!ring-0 \
    hover:!ring-offset-0 active:!translate-y-0',
  ],
  solid: [
    'cursor-not-allowed !bg-disabled  !text-white hover:!ring-0 \
    hover:!ring-offset-0 active:!translate-y-0',
  ],
  onlyText: [
    'cursor-not-allowed !bg-transparent !border-transparent  !text-disabled hover:!ring-0 \
    hover:!ring-offset-0 active:!translate-y-0',
  ],
};

const selectedClasses = 'border-4 !border-white';

type ButtonProps = PropsWithChildren<{
  ariaLabel?: string;
  id?: string;
  className?: string;
  color?: Color;
  disabled?: boolean;
  fullWidth?: boolean;
  iconOnly?: boolean;
  onClick?: () => void;
  rounded?: Rounded;
  size?: Size;
  style?: Style;
  target?: '_self' | '_blank';
  title?: string;
  url?: string;
  loading?: boolean;
  selected?: boolean;
  hotKey?: string;
}>;

const Button: FC<ButtonProps> = (props) => {
  const {
    ariaLabel,
    children,
    className,
    color = 'blue',
    disabled,
    fullWidth,
    id,
    iconOnly,
    onClick,
    rounded = 'full',
    size = 'medium',
    style = 'solid',
    target,
    title,
    url,
    loading,
    hotKey,
    selected,
  } = props;

  const { showContactForm } = useAppSelector(selectUiState) || {
    showContactForm: false,
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (showContactForm) return false;

      const combo = event.metaKey || event.ctrlKey || event.altKey;
      const key = event.key?.toUpperCase();
      if (!combo && hotKey && hotKey.toUpperCase() === key) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onClick && onClick();
      }
    },
    [hotKey, onClick],
  );

  useEffect(() => {
    if (hotKey) {
      document.body.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (hotKey) {
        document.body.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleKeyDown, hotKey]);

  const getDisabledClasses = () => (disabled ? disabledClasses[style] : []);

  const classes = classNames(
    commonClasses,
    focusClasses,
    style !== 'onlyText' && hoverClasses,
    activeClasses,
    ...(style !== 'onlyText' ? sizeClasses[size] : []),
    ...roundedClasses[rounded],
    ...styleClasses[style][color],
    fullWidth ? 'w-full' : 'w-min',
    ...getDisabledClasses(),
    iconOnly && 'aspect-square !p-0',
    selected && selectedClasses,
    className,
  );

  if (disabled) {
    return (
      <button id={id} disabled className={classes} aria-label={ariaLabel}>
        <Text
          elementType="div"
          {...textElemProps[size]}
          className="h-min flex items-center leading-none"
        >
          {loading && <LoadingSpinner />}
          {title || children}
        </Text>
      </button>
    );
  }

  return url ? (
    <NextLink href={url} id={id} className={classes} target={target}>
      <Text
        elementType="div"
        {...textElemProps[size]}
        className="h-min flex items-center leading-none"
      >
        {loading && <LoadingSpinner />}
        {title || children}
      </Text>
    </NextLink>
  ) : (
    <button
      id={id}
      className={classes}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <Text
        elementType="div"
        {...textElemProps[size]}
        className="h-min flex items-center leading-none"
      >
        {loading && <LoadingSpinner />}
        {title || children}
      </Text>
    </button>
  );
};

export default Button;
