import { RecipeLink } from 'type/recipe';
import { Room, RoomOptions } from 'type';

const create = (room: Room, roomOptions: RoomOptions): RecipeLink => ({
  _type: 'recipeLink',
  slugs: {
    room: room.slug.current,
    lightingSolution: roomOptions.lightingSolution?.slug.current,
    lightingControl: roomOptions.controlType,
    presenceLevel:
      roomOptions.controlType !== 'manual' && roomOptions.adjustments
        ? roomOptions.adjustments['presence-level']?.slug.current
        : undefined,
  },
});

const createFullUrl = (id: string, locale: string) =>
  `${window.location.protocol}//${window.location.host}/${locale}/share/${id}`;

export const RecipeLinkFactory = { create, createFullUrl };
