import {
  BaseInputField,
  BaseInputFieldProps,
} from '@grace-studio/graceful-next/components';
import { FC } from 'react';

type InputFieldType = BaseInputFieldProps & {
  className?: string;
  wrapperClassName?: string;
  labelClassName?: string;
};

const InputField: FC<InputFieldType> = (props) => {
  return (
    <div className="w-full">
      <BaseInputField {...props} />
    </div>
  );
};

export default InputField;
