import { BaseTextArea, Form } from '@grace-studio/graceful-next/components';
import { useGoogleTagManager } from '@grace-studio/graceful-next/hooks';
import classNames from 'classnames';
import Button from 'components/ui/Button';
import Text from 'components/ui/Text';
import InputField from 'components/ui/forms/InputField';
import { RecipeLinkFactory } from 'factories/recipeLinkFactory';
import { useTranslation } from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { selectGeneralState } from 'store/generalSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRoomState, selectSelectedRoomOptions } from 'store/roomSlice';
import { selectUiState, setUi } from 'store/uiSlice';
import { EMAIL_REGEX } from 'utils/forms';
import { object, string, ValidationError } from 'yup';

type ContactFormType = {
  className?: string;
};

type FormProps = {
  name: string;
  company: string;
  email: string;
  message?: string;
};

const initialFormData = {
  name: '',
  company: '',
  email: '',
};

const ContactForm: FC<ContactFormType> = () => {
  const translation = useTranslation();
  const general = useAppSelector(selectGeneralState);
  const emailTo = general?.contactEmail || 'daniel.melkersson@gracestudio.se';
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState<FormProps>(initialFormData);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [formStatus, setFormStatus] = useState<{
    loading: boolean;
    sent: boolean;
  }>({ loading: false, sent: false });
  const { locale = 'en' } = useRouter();
  const currentRoom = useAppSelector(selectRoomState);
  const currentRoomOptions = useAppSelector(selectSelectedRoomOptions);
  const ui = useAppSelector(selectUiState) || {};
  const gtm = useGoogleTagManager();

  const validationSchema = {
    name: string().required(translation('form.validations.name')),
    phone: string().required(translation('form.validations.phone')),
    jobTitle: string().required(translation('form.validations.jobTitle')),
    company: string().required(translation('form.validations.company')),
    email: string()
      .matches(EMAIL_REGEX, translation('form.errors.email'))
      .required(translation('form.validations.email')),
  };

  const createSharingLink = async (): Promise<string | null> => {
    const recipeLink = RecipeLinkFactory.create(
      currentRoom,
      currentRoomOptions,
    );

    try {
      const response = await fetch('/api/create-recipe-link', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(recipeLink),
      });

      const data = await response.json();

      return RecipeLinkFactory.createFullUrl(data.id, locale);
    } catch (error) {
      console.error(error);

      return null;
    }
  };

  async function handleSubmit() {
    gtm.trackEvent({
      event: 'submit_contact_form',
    });

    setFormStatus({
      loading: true,
      sent: false,
    });
    const sharingLink = await createSharingLink();

    if (!sharingLink) {
      setFormStatus({
        loading: false,
        sent: false,
      });
      setErrorMessage(translation('form.sharingLinkError'));

      return false;
    }

    fetch('/api/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formValues,
        emailTo: emailTo,
        sharingLink: sharingLink,
      }),
    }).then((response) => {
      if (response.ok) {
        setFormStatus({
          loading: false,
          sent: true,
        });
      } else {
        setFormStatus({
          loading: false,
          sent: false,
        });
        setErrorMessage(translation('form.error'));
      }
    });
  }
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const inputClasses =
    'w-full border border-grey-400 rounded-md bg-grey-50 p-2';
  const labelClasses = 'small-caps font-bold text-sm';
  const errorClasses = '!border-red-500';

  const hasErrors = (name: string) => formErrors.includes(name);

  return (
    <div className="relative flex flex-col gap-3 items-center justify-center">
      <Text elementType="h3" size="large" className="!font-bold">
        {translation('form.title')}
      </Text>
      <Form
        wrapperClassName="w-full"
        disabled={formStatus.sent}
        onSubmit={handleSubmit}
        validationSchema={object(validationSchema)}
        onValuesChange={async (values: any) => {
          setFormValues(values);
          await object()
            .shape(validationSchema)
            .validate(values, { abortEarly: false })
            .then(function () {
              setIsFormValid(true);
              setErrorMessage(undefined);
              setFormErrors([]);
            })
            .catch(function (err) {
              const fieldErrors = (err as ValidationError).inner.reduce<
                Record<string, string>
              >((acc, error) => {
                if (error.path) {
                  acc[error.path] = error.message;
                }

                return acc;
              }, {});

              const affectedNames = Object.keys(fieldErrors).filter(
                (name) => values[name]?.length > 0,
              );

              const errMess = Object.keys(fieldErrors)
                .filter((key) => affectedNames.includes(key))
                .map((key) => fieldErrors[key]);

              setErrorMessage(errMess.join(', '));
              setFormErrors(affectedNames);

              setIsFormValid(false);
            });
        }}
      >
        <div className="flex flex-col items-stretch justify-stretch gap-3">
          <div className="flex flex-row gap-2 w-full  justify-stretch items-stretch">
            <InputField
              className={classNames(
                inputClasses,
                hasErrors('name') && errorClasses,
              )}
              labelClassName={labelClasses}
              label={translation('form.fields.name')}
              type="text"
              name="name"
            />
            <InputField
              className={classNames(
                inputClasses,
                hasErrors('phone') && errorClasses,
              )}
              labelClassName={labelClasses}
              label={translation('form.fields.phone')}
              type="text"
              name="phone"
            />
          </div>
          <div className="flex flex-row gap-2 w-full  justify-stretch items-stretch">
            <InputField
              className={classNames(
                inputClasses,
                hasErrors('company') && errorClasses,
              )}
              labelClassName={labelClasses}
              label={translation('form.fields.company')}
              type="text"
              name="company"
            />
            <InputField
              className={classNames(
                inputClasses,
                hasErrors('jobTitle') && errorClasses,
              )}
              labelClassName={labelClasses}
              label={translation('form.fields.jobTitle')}
              type="text"
              name="jobTitle"
            />
          </div>
          <InputField
            className={classNames(
              inputClasses,
              hasErrors('email') && errorClasses,
            )}
            labelClassName={labelClasses}
            label={translation('form.fields.email')}
            type="email"
            name="email"
          />
          <BaseTextArea
            label={translation('form.fields.message')}
            className={classNames(inputClasses, 'resize-none h-[120px]')}
            labelClassName={labelClasses}
            wrapperClassName="w-full "
            name="message"
          />
          {errorMessage && (
            <div className="p-2 bg-red-200 rounded-md font-bold text-sm">
              {errorMessage}
            </div>
          )}
          <Button
            disabled={formStatus.loading || formStatus.sent || !isFormValid}
            loading={formStatus.loading}
            className="self-end"
          >
            {translation('form.fields.submit')}
          </Button>
        </div>
      </Form>

      {formStatus.sent && (
        <div className="absolute bg-white p-3 top-0 bottom-0 left-0 right-0 flex flex-col text-center items-center justify-center">
          <h3>{translation('form.success.title')}</h3>
          <p className="mb-[40px]">{translation('form.success.text')}</p>
          <Button
            onClick={() =>
              dispatch(
                setUi({
                  ...ui,
                  showContactForm: false,
                }),
              )
            }
          >
            {translation('form.success.btnText')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
